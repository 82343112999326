<template>

    <div>
        <v-container id="dashboard" fluid tag="section">
            <v-text-field class="mt-4" :label="$t('datatable.write_to_search')" outlined append-icon="mdi-magnify"
                v-model="search">
            </v-text-field>
            <v-data-table :headers="headers" :items="desserts" sort-by="phone" class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title> {{ $t("Drawer.corridors") }} </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="890px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" @click="editedIndex = -1 " dark class="mb-2" v-bind="attrs"
                                    v-on="on">
                                    {{ $t("add_new") }}
                                </v-btn>
                            </template>
                            <v-stepper v-model="e1">
                                <v-stepper-header>
                                    <v-stepper-step editable :complete="e1 > 1" v-if="editedIndex > -1" step="1"
                                        style="    color: #fff;">
                                        {{formTitle}}
                                    </v-stepper-step>

                                    <v-stepper-step :complete="e1 > 1" v-else step="1" style="    color: #fff;">
                                        {{formTitle}}
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <!-- <v-stepper-step editable v-if="editedIndex > -1" :complete="e1 > 2" step="2">
                                        {{ $t("add_pic") }}
                                    </v-stepper-step> -->



                                    <v-divider></v-divider>


                                </v-stepper-header>



                                <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <v-card class="mb-12" flat>

                                            <v-form ref="form" v-model="valid">

                                                <v-card-text>
                                                    <v-container>

                                                        <v-row>

                                                            <!-- more -->

                                                            <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-textarea v-model="editedItem.more.ar"
                                                                    :rules="messageRules" required
                                                                    :label="$t('datatable.more')+' '+$t('arbic')"
                                                                    outlined>
                                                                </v-textarea>
                                                            </v-col>



                                                            <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-textarea v-model="editedItem.more.en"
                                                                    :label="$t('datatable.more')+' '+$t('en')" outlined>
                                                                </v-textarea>
                                                            </v-col>



                                                            <!-- pack -->
                                                            <!-- <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-textarea v-model="editedItem.pack" :rules="messageRules"  required
                                                                    :label="$t('datatable.pack')"
                                                                    outlined>
                                                                </v-textarea>
                                                            </v-col> -->






                                                            <!-- size -->
                                                            <!-- <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-textarea v-model="editedItem.size" :rules="messageRules"  required
                                                                    :label="$t('datatable.size')"
                                                                    outlined>
                                                                </v-textarea>
                                                            </v-col> -->



                                                            <v-col class="py-0" cols="6" sm="12" md="6">
                                                                <v-text-field :label="$t('Drawer.choose_img')"
                                                                    @click='pickImg' v-model='imageName'
                                                                    prepend-icon='fas fa-paperclip'>
                                                                </v-text-field>
                                                                <input type="file" style="display: none" ref="image"
                                                                    accept="image/*" @change="onFilePicked">

                                                            </v-col>


                                                            <v-col class="py-0" cols="6" sm="6" md="6">
                                                                <v-img :src="imageUrl" height="150"
                                                                    :lazy-src="thumb_small" v-if="imageUrl" />
                                                            </v-col>



                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>

                                            </v-form>
                                        </v-card>

                                        <v-btn color="primary" :loading="loadSave" @click="save()">
                                            {{$t("save")}}
                                        </v-btn>

                                        <v-btn text @click="close()">
                                            {{$t("close")}}
                                        </v-btn>

                                    </v-stepper-content>


                                </v-stepper-items>
                            </v-stepper>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">

                            <v-btn text @click="editItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                                {{ $t("edit") }}</v-btn>


                        </template>
                        <span>{{ $t("edit") }} </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text @click="deleteItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                                {{$t('Delete')}}</v-btn>

                        </template>
                        <span>{{$t('Delete')}}</span>
                    </v-tooltip>



                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>


<style>
    /* .v-stepper__label
{
    color: #fff !important;
} */
</style>
<script>
    //UploudeImages
    import Axios from "axios";
    import Swal from "sweetalert2";
    export default {
        data() {
            return {
                desserts: [

                ],
                cats: [],
                dialog: false,
                loadSave: false,
                file_name: null,
                e1: 1,
                Url: "http://127.0.0.1:8000/api/",
                x: this.$i18n.locale,

                imagesUrl: '',
                imageName: '',
                show_loading: false,
                imageUrl: '',
                img_old: '',
                imageFile: '',

                imagesName: ' ',
                editedIndex: -1,
                editedItem: {
                    images: [],
                    image: {
                        image_name: ''
                    },

                    id: "",
                    more: {
                        ar: "",
                        en: ""
                    },
                    views: "",
                    time: "",
                    size: "",
                    rating: "",
                    downloads: "",
                    pack: "",

                },
                items: [

                ],

                right: null,
                vvv: {},
                messageRules: [
                    v => !!v || this.$t("valMessage")
                ],
            }
        },
        watch: {
            '$i18n.locale': function (newVal, oldVal) {

                this.x = newVal;
                this.get();
            },
        },
        methods: {
            get() {
                return this.headers = [{
                        text: '#ID',
                        align: "start",
                        value: "id"
                    },

                    {
                        text: this.$t('datatable.title'),
                        align: "start",
                        value: this.x == 'en' ? "more.en" : "more.ar"
                    },
                    {
                        text: this.$t('Processes'),
                        value: "actions",
                        sortable: false
                    }
                ];
            },

            validate() {
                valid: true;
            },
            editItem(item) {
                this.e1 = 1;
                this.editedIndex = this.desserts.indexOf(item);
                this.$store.dispatch("art_uploudess", item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
                this.img_old = ''
                this.imageName = ''
                this.imageFile = null
                this.imageUrl = ''

                this.img_old = this.editedItem['photo'];
                this.imageUrl = this.resUrl + '/upload/corridors/370/' + this.img_old;

            },

            pickFile() {
                this.$refs.file.click()
            },

            onPickfile(e) {

                this.file = this.$refs.file.files[0];
                let file = e.target.files;
                this.file = file[0]
                this.file_name = file[0].name
            },


            close() {

                this.e1 = 1;
                this.loadSave = false;
                this.img_old = ''
                this.imageName = ''
                this.imageFile = ''
                this.imageUrl = ''
                this.dialog = false;
                this.editedItem = {
                    images: [],
                    image: {
                        image_name: ''
                    },
                    id: "",
                    more: {
                        ar: "",
                        en: ""
                    },
                    views: "",
                    time: "",
                    size: "",
                    rating: "",
                    downloads: "",
                    pack: "",

                }

                this.$refs.form.resetValidation();
                this.$store.dispatch("art_uploudess", this.vvv);
                this.$store.dispatch("art_uploudess", this.vvv);

            },
            initialize() {
                this.loading = true;
                Axios.get("corridors", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            authorizations: "Bearer " + this.$store.state.AdminInfo.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.desserts = res.data.data;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },

            pickImg() {
                this.$refs.image.click()
            },


            pickFiles() {
                this.$refs.images.click()

            },


            onFilePicked(e) {

                const files = e.target.files

                if (files[0] !== undefined) {

                    this.imageName = files[0].name

                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }


                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {

                        this.imageUrl = fr.result
                        this.imageFile = files[0]


                    })
                } else {
                    // this.imageName = ''
                    // this.imageFile = ''
                    // this.imageUrl = ''


                }
            },


            deleteItem(item) {

                Swal.fire({
                    title: this.$t('sure_process'),
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then(result => {
                    if (result.value) {
                        

                            Axios.delete("corridors/" + item.id, {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    authorizations: "Bearer " + this.$store.state.AdminInfo.token
                                }
                            })


                            .then(() => {
                                this.initialize();
                                this.$swal.fire(this.$t('Successfully'), this.$t('done'), "success");

                            })
                            .catch(() => {
                                this.$swal.fire(this.$t('not_successful'), this.$t('not_done'), "error");
                            });
                    }
                });
            },

            save() {
                if (this.$refs.form.validate()) {


                    if (this.imageFile == '') {
                        this.$swal.fire({
                            title: "يجب اختيار صوره",
                            text: "",
                            icon: "error",
                            confirmButtonText: "اغلاق",
                        });

                    } else {
                        this.loadSave = true;
                        if (this.editedIndex > -1) {

                            var update = {
                                translations: [{

                                        more: this.editedItem.more.ar,

                                    },
                                    {

                                        more: this.editedItem.more.en,

                                    }
                                ],

                                pack: this.editedItem.pack,
                                size: this.editedItem.size,
                                views: 'null',
                                time: 'null',
                                rating: 'null',
                                downloads: 'null',

                            }
                            this.axios
                                .put("corridors/update/" + this.editedItem.id, update, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Accept: "application/json",
                                        authorizations: "Bearer " + this.$store.state.AdminInfo.token
                                    },
                                })
                                .then(() => {

                                    if (this.imageFile != null) {
                                        let formData = new FormData();
                                        // const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                                        formData.append('photo', this.imageFile);
                                        Axios.post('corridors/uploadImage/' + this.editedItem.id,
                                            formData, {


                                                headers: {
                                                    'Content-Type': 'multipart/form-data',
                                                    Accept: "application/json",
                                                    authorizations: "Bearer " + this.$store.state.AdminInfo.token
                                                },
                                            }
                                        )
                                        this.img_old = ''
                                        this.imageName = ''
                                        this.imageFile = ''
                                        this.imageUrl = ''
                                    }
                                    this.$refs.form.resetValidation();
                                    this.initialize();

                                    this.$swal.fire({
                                        title: "تم تعديل ",
                                        text: "",
                                        icon: "success",
                                        confirmButtonText: "اغلاق",
                                    });
                                    this.close();

                                })
                                .catch(() => {
                                    this.loadSave = false;
                                    this.$swal.fire({
                                        title: "تاكد من ملى المعلومات",
                                        text: "",
                                        icon: "error",
                                        confirmButtonText: "اغلاق",
                                    });
                                });
                        } else {

                            var savedate = {

                                translations: [{

                                        more: this.editedItem.more.ar,

                                    },
                                    {

                                        more: this.editedItem.more.en,

                                    }
                                ],

                                pack: this.editedItem.pack,
                                size: this.editedItem.size,
                                views: 'null',
                                time: 'null',
                                rating: 'null',
                                downloads: 'null',

                            }


                            this.axios
                                .post("corridors", savedate, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Accept: "application/json",
                                        authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                    },
                                })
                                .then((res) => {


                                    this.editedItem = res.data.data;

                                    if (this.imageFile != null) {
                                        let formData = new FormData();
                                        // const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                                        formData.append('photo', this.imageFile);
                                        Axios.post('corridors/uploadImage/' + this.editedItem.id,
                                                formData, {
                                                

                                                    headers: {
                                                        'Content-Type': 'multipart/form-data',
                                        Accept: "application/json",
                                        authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                    },
                                                }
                                            )
                                            .then(response => {
                                                response

                                                this.e1 = 2
                                                this.loadSave = false;
                                                this.img_old = ''
                                                this.imageName = ''
                                                this.imageFile = ''

                                                // }
                                            })

                                        // this.img_old = ''

                                        this.editedIndex = -1;
                                        this.loadSave = false;
                                        this.imageName = ''

                                        // this.initialize();
                                    }
                                    this.$swal.fire({
                                        title: 'تم رفع ',
                                        text: "",
                                        icon: "success",
                                        confirmButtonText: this.$t('close'),
                                    });

                                    this.$refs.form.resetValidation();
                                    this.initialize();
                                    this.close();

                                })
                                .catch((err) => {
                                    err
                                    /// this.loadSave = false;
                                });
                        }
                    }
                }

            },

        },

        components: {
            UploudeImages: () => import("../../components/core/UploudeImages.vue"),

        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? this.$t('add_new') : this.$t('update');

            },
        },
        created() {
            this.initialize();
            this.get();
        },

    }
</script>